// actions.js
import {
    CALCULATE_RISK_REQUEST,
    CALCULATE_RISK_SUCCESS,
    CALCULATE_RISK_FAILURE,
  } from "../types";
  
  export const calculateRiskRequest = (data) => ({
    type: CALCULATE_RISK_REQUEST,
    payload: data, // Pass any necessary data for risk calculation
  });
  
  export const calculateRiskSuccess = (result) => ({
    type: CALCULATE_RISK_SUCCESS,
    payload: result,
  });
  
  export const calculateRiskFailure = (error) => ({
    type: CALCULATE_RISK_FAILURE,
    payload: error,
  });
  