// kycSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_KYC_DETAIL_REQUEST } from "../types";
import {
  fetchKycDetailSuccess,
  fetchKycDetailFailure,
} from "../actions/kycActions";
import axios from "axios";
import { DGTL_BACKEND_URL } from "../URL";

function* fetchKycDetail(action) {
  const kycId = action.payload; // Extract kycId from the action payload
  try {
    const response = yield call(
      axios.get,
      `${DGTL_BACKEND_URL}/admin/kyc/getKYCDecrypt/${kycId}` // Use kycId in the API call
    );
    const { data } = response.data;

    yield put(fetchKycDetailSuccess(data));
  } catch (error) {
    yield put(fetchKycDetailFailure(error.message));
  }
}

function* kycSaga() {
  yield takeLatest(FETCH_KYC_DETAIL_REQUEST, fetchKycDetail);
}

export default kycSaga;
