import React, { useState } from "react";

const Tabs = ({ HandleTabs }) => {
  const [activeTab, setActiveTab] = useState("");

  const tabs = [
    "Show All",
    "Risk Profile",
    "Risk Score",
    "Risks Identified",
    "Impact",
    "Analysis",
    "Risk Mitigation",
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    HandleTabs(tab);
  };

  return (
    <section className="container py-3">
      <div className="row">
        <div className="col-lg-12 tabs-risktable">
          {tabs.map((tab) => (
            <span
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={tab === activeTab ? "active" : ""}
            >
              {tab}
            </span>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Tabs;
