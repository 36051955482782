import { LOAD_USER_SUCCESS, LOAD_USER_FAILURE } from "../types";

// Action creator for successful user load
export const loadUserSuccess = (userData, companyData) => ({
  type: LOAD_USER_SUCCESS,
  payload: { user: userData, company: companyData },
});

// Action creator for failed user load
export const loadUserFailure = (error) => ({
  type: LOAD_USER_FAILURE,
  payload: error,
});
