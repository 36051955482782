// reducer.js

import { GET_SCORE_LIST, GET_COUNREIES_SCORE_LIST } from "../types";

const initialState = {
  scores: null,
  countriesScores: null,
  loading: false,
  error: null,
};

const scoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCORE_LIST:
      return {
        ...state,
        scores: action.payload,
        loading: false,
      };
    case GET_COUNREIES_SCORE_LIST:
      return {
        ...state,
        countriesScores: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default scoreReducer;
