import React from 'react';
import "./Forbidden.css";

const Forbidden = () => {
  return (
    <div>
      {/* SVG Definitions */}


      {/* Content Container */}
      <div className="container-forbidden">
        <h1>403</h1>
        <p>access not granted</p>
      </div>
    </div>
  );
};

export default Forbidden;
