import { GET_TABS_LIST } from "../types";
import { RISK_ENGINE_BACKEND_URL } from "../URL";
import toast from "react-hot-toast";

const token =  window.location.href.split("/")[5] || localStorage.getItem("token");
export const GetTabs = (companyId) => (dispatch) => {
  if (!token) {
    console.log("token", token)
    toast.error("Token not found. Please log in.");
    return;
  }
  fetch(`${RISK_ENGINE_BACKEND_URL}/getTabs/?companyId=${companyId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      dispatch({
        type: GET_TABS_LIST,
        payload: res?.data,
      });
    })
    .catch((error) => {
      console.log(error);
      // Additional error handling if needed
    });
};

export const CreateTab = (body, companyId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }

  if (typeof body !== "object" || body === null) {
    console.error("Invalid body:", body);
    return;
  }

  fetch(`${RISK_ENGINE_BACKEND_URL}/createTab`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetTabs(companyId));
    })
    .catch((error) => console.error("Error:", error));
};


export const UpdateTab = (body, companyId, tabId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }

  if (typeof body !== "object" || body === null) {
    console.error("Invalid body:", body);
    return;
  }

  fetch(`${RISK_ENGINE_BACKEND_URL}/updateTab/${tabId}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((data) => {
      toast.success(data?.message);
      dispatch(GetTabs(companyId));
    })
    .catch((error) => console.error("Error:", error));
};
