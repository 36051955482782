import { GET_RISK_TABLE_DATA } from "../types";
import { RISK_ENGINE_BACKEND_URL, DGTL_BACKEND_URL } from "../URL";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const token = window.location.href.split("/")[5] || localStorage.getItem("token") ;

export const saveResults = (body, kycID, companyID, navigate) => () => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }
  const postBody = {
    riskResults: body,
    kycID,
    companyID,
  };

  console.log("postBody", postBody);
  fetch(`${DGTL_BACKEND_URL}/admin/kyc/save-risk`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      console.log(res);
      navigate("/risk-table");
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error?.message);
      } else if (error.request) {
        // toast.error(error?.request);
      } else {
        // toast.error(error?.message);
      }
    });
};

export const calculateRisk =
  (body, kycID, companyID, navigate) => (dispatch) => {
    if (!token) {
      toast.error("Token not found. Please log in.");
      return;
    }
    fetch(`${RISK_ENGINE_BACKEND_URL}/risk/calculate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.status === 401) {
          toast.error("Unauthorized");
          return null; // Skip the next then block
        } else {
          return res.json();
        }
      })
      .then((res) => {
        dispatch({
          type: GET_RISK_TABLE_DATA,
          payload: res,
        });
        console.log(res);
        dispatch(saveResults(res, kycID, companyID, navigate));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.error) {
          // toast.error(error?.response?.data?.error?.message);
        } else if (error.request) {
          // toast.error(error?.request);
        } else {
          // toast.error(error?.message);
        }
      });
  };

export const getResults = (kycId) => (dispatch) => {
  if (!token) {
    toast.error("Token not found. Please log in.");
    return;
  }

  fetch(`${DGTL_BACKEND_URL}/admin/kyc/risk/${kycId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (res.status === 401) {
        toast.error("Unauthorized");
        return null; // Skip the next then block
      } else {
        return res.json();
      }
    })
    .then((res) => {
      dispatch({
        type: GET_RISK_TABLE_DATA,
        payload: res?.data[0]?.riskResults,
      });
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(error?.response?.data?.error?.message);
      } else if (error.request) {
        // toast.error(error?.request);
      } else {
        // toast.error(error?.message);
      }
    });
};
