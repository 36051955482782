// src/redux/reducers/riskTableReducer.js
import { GET_RISK_TABLE_DATA } from "../types";

const initialState = {
  riskTableData: null,
  loading: false,
  error: null,
};

const riskTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_TABLE_DATA:
      return {
        ...state,
        riskTableData: action.payload,
      };
    default:
      return state;
  }
};

export default riskTableReducer;
