// reducer.js
import {
  FETCH_KYC_DETAIL_REQUEST,
  FETCH_KYC_DETAIL_SUCCESS,
  FETCH_KYC_DETAIL_FAILURE,
} from "../types";

const initialState = {
  kycDetail: null,
  loading: false,
  error: null,
};

const kycReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KYC_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_KYC_DETAIL_SUCCESS:
      return {
        ...state,
        kycDetail: action.payload,
        loading: false,
      };
    case FETCH_KYC_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default kycReducer;
