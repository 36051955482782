// riskSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { CALCULATE_RISK_REQUEST } from "../types";
import {
  calculateRiskSuccess,
  calculateRiskFailure,
} from "../actions/calculateRiskActions";
import axios from "axios";
import { RISK_ENGINE_BACKEND_URL } from "../URL";

function* calculateRisk(action) {
  const requestData = action.payload; // Extract data from the action payload
  try {
    const response = yield call(
      axios.post,
      `${RISK_ENGINE_BACKEND_URL}/risk/calculate`, // Adjust the endpoint as needed
      requestData
    );
    const { data } = response.data;
    yield put(calculateRiskSuccess(data));
  } catch (error) {
    yield put(calculateRiskFailure(error.message));
  }
}

function* calculateRiskSaga() {
  yield takeLatest(CALCULATE_RISK_REQUEST, calculateRisk);
}

export default calculateRiskSaga;
