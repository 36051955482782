import { call, put, takeLatest } from "redux-saga/effects";
import { loadUserSuccess, loadUserFailure } from "../actions/userActions";
import axios from "axios";
import { DGTL_BACKEND_URL } from "../URL";
import toast from "react-hot-toast";

// API call function
const fetchUserData = async () => {
  const token = window.location.href.split("/")[5] || localStorage.getItem("token"); // Adjust as needed
  const response = await axios.get(`${DGTL_BACKEND_URL}/admin/users/loaduser`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.data;
};

// Saga worker function
function* loadUser() {
  try {
    const data = yield call(fetchUserData);
    yield put(loadUserSuccess(data.userData, data.company));
  } catch (error) {
    console.log(error)
    // toast.error(error.response.data.message);
    yield put(loadUserFailure(error.message));
  }
}

// Watcher saga
function* userSaga() {
  yield takeLatest("LOAD_USER_REQUEST", loadUser); // Trigger saga on action dispatch
}

export default userSaga;
