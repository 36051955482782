// types.js
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const FETCH_KYC_DETAIL_REQUEST = 'FETCH_KYC_DETAIL_REQUEST';
export const FETCH_KYC_DETAIL_SUCCESS = 'FETCH_KYC_DETAIL_SUCCESS';
export const FETCH_KYC_DETAIL_FAILURE = 'FETCH_KYC_DETAIL_FAILURE';


// tabs types

export const GET_TABS_LIST = 'GET_TABS_LIST';

// score types
export const GET_SCORE_LIST = 'GET_SCORE_LIST';
export const GET_COUNREIES_SCORE_LIST = 'GET_COUNREIES_SCORE_LIST';

// calculate risk types
export const CALCULATE_RISK_REQUEST = 'CALCULATE_RISK_REQUEST';
export const CALCULATE_RISK_SUCCESS = 'CALCULATE_RISK_SUCCESS';
export const CALCULATE_RISK_FAILURE = 'CALCULATE_RISK_FAILURE';

// New Risk Table Data Type
export const GET_RISK_TABLE_DATA = "GET_RISK_TABLE_DATA";