// actions.js
import {
  FETCH_KYC_DETAIL_REQUEST,
  FETCH_KYC_DETAIL_SUCCESS,
  FETCH_KYC_DETAIL_FAILURE,
} from "../types";

export const fetchKycDetailRequest = (kycId) => ({
  type: FETCH_KYC_DETAIL_REQUEST,
  payload: kycId, // Include kycId in the payload
});

export const fetchKycDetailSuccess = (data) => ({
  type: FETCH_KYC_DETAIL_SUCCESS,
  payload: data,
});

export const fetchKycDetailFailure = (error) => ({
  type: FETCH_KYC_DETAIL_FAILURE,
  payload: error,
});
