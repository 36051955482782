import React from "react";
import { useNavigate } from "react-router-dom";

import Nationality from "./images/Nationality.png";
import residence from "./images/residence.png";
import Income from "./images/Income.png";
import legal from "./images/legal.png";
import Source from "./images/Source.png";
import product from "./images/product.png";
import product1 from "./images/product1.png";
import transactions from "./images/transactions.png";
import member from "./images/member.png";
import geograpgh from "./images/geograpgh.png";
import news from "./images/news.png";
import debt from "./images/debt.png";
import documents from "./images/documents.png";
import add from "./images/add.png";
import Dropdown from "react-bootstrap/Dropdown";

const sampleData = [
  { title: "Sample Title 1", risk: "High", score: 85 },
  { title: "Sample Title 2", risk: "Medium", score: 75 },
  { title: "Sample Title 3", risk: "Low", score: 65 },
];

const ManageSetting = () => {
  const navigate = useNavigate();

  const openTablePage = (data) => {
    navigate("/table", { state: { data } });
  };

  return (
    <div className="manage-setting-page">
      <div className="row">
        <div className="col-md-3 mb-4">
          <div className="card p-4 rounded-4 shadow">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <h6 className="mb-0">Country Of Operations</h6>
              <div>
                <Dropdown className="text-end dropdown-dots" drop="bottom">
                  <Dropdown.Toggle variant="primary" className="px-2 py-1">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-pencil me-1"></i> Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-trash me-1"></i> Delete{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card p-4 rounded-4 shadow">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <h6 className="mb-0">Country Of Nationality</h6>
              <div>
                <Dropdown className="text-end dropdown-dots" drop="bottom">
                  <Dropdown.Toggle variant="primary" className="px-2 py-1">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-pencil me-1"></i> Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-trash me-1"></i> Delete{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card p-4 rounded-4 shadow">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <h6 className="mb-0">Source Of Income Legal Structure</h6>
              <div>
                <Dropdown className="text-end dropdown-dots" drop="bottom">
                  <Dropdown.Toggle variant="primary" className="px-2 py-1">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-pencil me-1"></i> Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-trash me-1"></i> Delete{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card p-4 rounded-4 shadow">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <h6 className="mb-0">Other Source of Income</h6>
              <div>
                <Dropdown className="text-end dropdown-dots" drop="bottom">
                  <Dropdown.Toggle variant="primary" className="px-2 py-1">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-pencil me-1"></i> Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-trash me-1"></i> Delete{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card p-4 rounded-4 shadow">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <h6 className="mb-0">Geographic Details</h6>
              <div>
                <Dropdown className="text-end dropdown-dots" drop="bottom">
                  <Dropdown.Toggle variant="primary" className="px-2 py-1">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-pencil me-1"></i> Edit{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);">
                      <i class="bi bi-trash me-1"></i> Delete{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-container">
        <div className="box box2" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Country Of Nationality</h2>
          </div>
          <img src={Nationality} alt="likes" />
        </div>

        <div className="box box3" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Country Of Residence</h2>
          </div>
          <img src={residence} alt="comments" />
        </div>

        <div className="box box4" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Source Of Income Legal Structure</h2>
          </div>
          <img src={Income} alt="published" />
        </div>
      </div>

      <div className="box-container box-container-spacing">
        <div className="box box1" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Source Of Income From Industry</h2>
          </div>
          <img src={legal} alt="Views" />
        </div>

        <div className="box box2" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Other Source of Income</h2>
          </div>
          <img src={Source} alt="Likes" />
        </div>

        <div className="box box3" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Products and Service Types</h2>
          </div>
          <img src={product} alt="Comments" />
        </div>

        <div className="box box4" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Purpose of Products</h2>
          </div>
          <img src={product1} alt="Published" />
        </div>
      </div>

      <div className="box-container box-container-spacing">
        <div className="box box1" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Transactions</h2>
          </div>
          <img src={transactions} alt="Views" />
        </div>

        <div className="box box2" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Member Types</h2>
          </div>
          <img src={member} alt="Likes" />
        </div>

        <div className="box box3" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Documents</h2>
          </div>
          <img src={documents} alt="Comments" />
        </div>

        <div className="box box4" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Geographic Details</h2>
          </div>
          <img src={geograpgh} alt="Published" />
        </div>
      </div>

      <div className="box-container box-container-spacing">
        <div className="box box1" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">News Search</h2>
          </div>
          <img src={news} alt="Views" />
        </div>

        <div className="box box2" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Debt To Income Ratio </h2>
          </div>
          <img src={debt} alt="likes" />
        </div>

        <div className="box box3" onClick={() => openTablePage(sampleData)}>
          <div className="text">
            <h2 className="topic-heading">Additional Details</h2>
          </div>
          <img src={add} alt="Comments" />
        </div>
      </div>
    </div>
  );
};

export default ManageSetting;
