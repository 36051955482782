import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import dashboardIcon from "../images/dashboardIcon.png";
import logo from "../images/logo.png";
import Msettings from "../images/Msettings.png";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  const [navOpen] = useState(true);

  return (
    <>
      <div className="main-container">
        <div className={navOpen ? "navcontainer" : "navcontainer navclose"}>
          <nav className="nav">
            <div className="nav-upper-options">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? "nav-option option1 active text-decoration-none"
                    : "nav-option text-decoration-none"
                }
              >
                <img src={logo} className="nav-img" alt="dashboard" />
                <div className="risk-engine-settings-text">Risk Engine setting</div>
              </NavLink>

              {/* <NavLink
                to="/kyc-details"
                className={({ isActive }) =>
                  isActive ? 'nav-option option1 active text-decoration-none' : 'nav-option text-decoration-none'
                }
              >
                <img src={details} className="nav-img" alt="invoices" />
                <div className="fw-bold">KYC details</div>
              </NavLink> */}

              {/* <NavLink
                to="/manage-setting"
                className={({ isActive }) =>
                  isActive
                    ? "nav-option option1 active text-decoration-none"
                    : "nav-option text-decoration-none"
                }
              >
                <img src={Msettings} className="nav-img" alt="report" />
                <div className="fw-bold">Manage Settings</div>
              </NavLink> */}
            </div>
          </nav>
        </div>
        <div className="main">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default MainLayout;
