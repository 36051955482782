// reducer.js

import { GET_TABS_LIST } from "../types";

  const initialState = {
    tabs: null,
    loading: false,
    error: null,
  };
  
  const tabReducer = (state = initialState, action) => {
    switch (action.type) {

      case GET_TABS_LIST:
        return {
          ...state,
          tabs: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default tabReducer;
  