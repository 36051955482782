// reducer.js
import {
    CALCULATE_RISK_REQUEST,
    CALCULATE_RISK_SUCCESS,
    CALCULATE_RISK_FAILURE,
  } from "../types";
  
  const initialState = {
    riskResult: null,
    riskLoading: false,
    riskError: null,
  };
  
  const calculateRiskReducer = (state = initialState, action) => {
    switch (action.type) {
      case CALCULATE_RISK_REQUEST:
        return {
          ...state,
          riskLoading: true,
        };
      case CALCULATE_RISK_SUCCESS:
        return {
          ...state,
          riskResult: action.payload,
          riskLoading: false,
        };
      case CALCULATE_RISK_FAILURE:
        return {
          ...state,
          riskError: action.payload,
          riskLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default calculateRiskReducer;
  